import React, { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Modal from "react-modal";

import leftArrowGrn from "assets/icons/arrow-left-green.svg";
import { modalStyles } from "styles/modals";

import { RootState } from "redux/reducers";

import StandardButton from "../../../../Common/buttons/StandardButton";
import Inquiry from "components/Common/forms/Inquiry";
import CustomReportNavigator from "./CustomReportNavigator";
import NewsFeed from "../../../report/ChildComponents/Sidebar/NewsFeed/NewsFeed";

Modal.setAppElement("#root");

type ToggleProps = { toggleRightSide?: boolean };
type T = {
  toggleRightSide: boolean;
};

const CustomReportSidebar: React.FC<T> = ( { toggleRightSide }) => {
  const { search } = useSelector((state: RootState) => state.customReport);
  const [toggleModal, setToggleModal] = useState(false);

  return (
    <Container id="CustomReportSidebar" className="side-menu" toggleRightSide={toggleRightSide}>
      <Wrapper>
        <ReportNavigatorDiv>
          {search.scrollPositions.length > 0 && <CustomReportNavigator />}
        </ReportNavigatorDiv>
        <CustomButton
          type="button"
          size="lg"
          color="green"
          outline={true}
          onClick={() => setToggleModal(true)}
        >
          {/* <img src={leftArrowGrn} alt="left arrow icon" /> */}
          Ask an Analyst
        </CustomButton>

        <Modal
          onRequestClose={() => setToggleModal(false)}
          isOpen={toggleModal}
          contentLabel="Ask an Analyst"
          style={modalStyles}
        >
          <Inquiry
            setToggleModal={setToggleModal}
            modalTitle="Ask an Analyst"
          />
        </Modal>
        <NewsFeed />
      </Wrapper>
    </Container>
  );
};

const Container = styled.div<ToggleProps>`
  /* height: 100vh; */
  position: sticky;
  top: 7.35rem;
  padding-right: 1rem;

  display: flex;
  background-color: #f6f8f9;

  > div {
    display: ${(props) => (props.toggleRightSide ? "flex" : "none")};
    width: ${(props) => (props.toggleRightSide ? "40rem" : "0rem")};
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

const CustomButton = styled(StandardButton)`
  margin-top: 2rem;

  > img {
    height: 4rem;
  }

  border: 1px solid ${(props) => props.theme.colors.idataBlue};
  border-radius: 100px;
  color: ${(props) => props.theme.colors.idataBlue};
  padding: 10px 2rem;

  &:hover {
    background-color: ${(props) => props.theme.colors.idataBlue};
    color: white;
  }
`;

const ReportNavigatorDiv = styled.div`
  // @media screen and (max-width: 1305px) {
  //   display: none;
  // }
`;

export default CustomReportSidebar;
