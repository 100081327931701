import React from "react";
import styled from "styled-components";

import FooterMenu from "./ChildComponents/FooterMenu";

const Footer: React.FC = () => {
  const currentYear: number = new Date().getFullYear();

  return (
    <Container>
      <Copyright>&copy; {currentYear} iData Research Inc.</Copyright>
      <FooterMenu />
    </Container>
  );
};

const Container = styled.footer`
  background-color: ${(props) => props.theme.colors.idataDarkBlue};
  box-shadow: 1rem 0rem 2rem 1rem rgba(0, 0, 0, 0.15);
  z-index: 999;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  font-size: 1.9rem;

  > * {
    margin: 1rem;
  }

  @media screen and (max-width: ${(props) => props.theme.bp.sm}px) {
    flex-direction: column;
  }
`;

const Copyright = styled.p`
  // margin: 0;
`;

export default Footer;
