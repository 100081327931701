import axios from "axios";
import styled from "styled-components";
import React, { useEffect, useState } from "react";

import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";

const Help: React.FC = () => {
  const [downloadLink, setDownloadLink] = useState<string>("");

  useEffect(() => {
    axios.get("/guide").then(response => {
      setDownloadLink(response.data);
      const link = document.createElement('a');
      link.href = response.data;
      document.body.appendChild(link);
      setTimeout(clickLink, 3000)

      function clickLink() {
        link.click();
      }
    });
  }, [])

  return (
    <>
      <Header />
      <Container>
        <h1>Downloading PDF Guide...</h1>
        Download not starting? Try this <a hidden={downloadLink ? false : true} href={downloadLink}>direct download link.</a>
      </Container>
      <Footer />
    </>
  );
};

const Container = styled.div`
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  /* min-height: 90vh; */
  overflow-y: auto;
  width: 100%;
  padding: 1rem;
  text-align: center;
`;

export default Help;
