import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { useAuth } from "contexts/AuthContext";
import { RootState } from "redux/reducers";
import { CustomChapterMeta } from "types/custom";
import bookmarkIcon from "assets/icons/bookmark-icon.svg";

import CustomReportMenuItem from "./CustomReportMenuItem";
import OptionsMenu from "../Options/OptionsMenu";

type ToggleProps = { toggleLeftSide?: boolean };
type T = {
  toggleLeftSide: boolean;
};

const CustomReportMenu: React.FC<T> = ({ toggleLeftSide }) => {
  const { currentUser } = useAuth();
  const { customReport } = useSelector((state: RootState) => state);
  const { chapterData, currentChapter, search, reportData } = customReport;
  const searchItems: number[] = search.chapters.map(({ chapter }) => chapter);

  useEffect(() => {
    let prevScrollPos = window.pageYOffset;

    function handleResize() {
      const headerElem = document.getElementById("Header");
      const headerHeight = headerElem!.offsetHeight;

      const reportHeaderElem = document.getElementById("CustomReportHeader");
      const reportHeaderHeight = reportHeaderElem!.offsetHeight;

      const reportMenuElem = document.getElementById("CustomReportMenu");
      const reportSidebarElem = document.getElementById("CustomReportSidebar");
      const reportContainer = document.getElementById("InnerContainer")?.parentElement;
      const reportHeader = document.getElementById("CustomReportHeader");

      if (reportContainer && reportHeader) {
        const h = (reportContainer.clientHeight - reportHeader.clientHeight) * 0.9925;
        if (reportMenuElem) {
          reportMenuElem.style.maxHeight = `${h}px`;
          reportMenuElem.style.top = `${reportHeaderHeight}px`;
        }
        if (reportSidebarElem) {
          reportSidebarElem.style.maxHeight = `${h}px`;
          reportSidebarElem.style.top = `${reportHeaderHeight}px`;
        }
      }

      const scrollPosition =
        document.documentElement.scrollTop || document.body.scrollTop;

      // https://www.w3schools.com/howto/howto_js_navbar_hide_scroll.asp
      let currScrollPos = window.pageYOffset;

      const sideMenus = document.getElementsByClassName("side-menu");

      if (prevScrollPos > currScrollPos) {
        for (let index = 0; index < sideMenus.length; index++) {
          const element = sideMenus[index] as HTMLElement;
          element.style["padding-top"] = `${reportHeaderHeight + 10}px`;
        }
        document.getElementById("CustomReportMain")!.style["margin-top"] = `${reportHeaderHeight + 10
          }px`;

        // scrolling up
        if (scrollPosition < reportHeaderHeight) {
          reportHeaderElem!.style.top = `${headerHeight}px`;
        } else {
          reportHeaderElem!.style.top = "0";
        }

        if (scrollPosition === 0) {
          reportMenuElem!.style.height = `91vh`;
          reportSidebarElem!.style.height = `91vh`;
        } else {
          reportMenuElem!.style.height = `100vh`;
          reportSidebarElem!.style.height = `100vh`;
        }
      } else {
        // scrolling down
        if (scrollPosition === 0) {
          reportHeaderElem!.style.top = `${headerHeight}px`;
          document.getElementById("CustomReportMain")!.style["margin-top"] = `${reportHeaderHeight + 10
            }px`;
          for (let index = 0; index < sideMenus.length; index++) {
            const element = sideMenus[index] as HTMLElement;
            element.style["padding-top"] = `${reportHeaderHeight + 10}px`;
          }
        } else {
          reportHeaderElem!.style.top = `-1000px`;

          for (let index = 0; index < sideMenus.length; index++) {
            const element = sideMenus[index] as HTMLElement;
            element.style.removeProperty("padding-top");
          }

          document
            .getElementById("CustomReportMain")!
            .style.removeProperty("margin-top");
        }
        reportMenuElem!.style.height = `100vh`;
        reportSidebarElem!.style.height = `100vh`;
      }
      prevScrollPos = currScrollPos;

      if (currScrollPos <= 100) {
        reportHeaderElem
          ?.style.removeProperty("top");

        reportMenuElem
          ?.style.removeProperty("padding-top");

        document
          .getElementById("CustomReportMain")
          ?.style.removeProperty("margin-top");

        reportSidebarElem
          ?.style.removeProperty("padding-top");
      }

    }

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleResize);

    handleResize();
  }, []);

  return (
    <Container id="CustomReportMenu" className="side-menu" toggleLeftSide={toggleLeftSide}>
      <Wrapper>
        <Bookmarks>
          <MenuHeader className="menuHeader">
            <BookmarkIcon src={bookmarkIcon} alt="bookmark icon" /> Bookmarks
          </MenuHeader>
          <ReportSubMenu>
            {chapterData.length > 0 ? (
              chapterData.map((item: CustomChapterMeta) => (
                <CustomReportMenuItem
                  key={item.id}
                  item={item}
                  chapter={currentChapter}
                  searchItems={searchItems}
                />
              ))
            ) : (
              <li>No chapters added</li>
            )}
          </ReportSubMenu>
        </Bookmarks>
        {reportData.user_email === currentUser.email && <OptionsMenu />}
      </Wrapper>
    </Container>
  );
};

const Container = styled.div<ToggleProps>`
  /* height: 100vh; */
  position: sticky;
  top: 7.35rem;
  padding-left: 1rem;

  display: flex;
  background-color: #f6f8f9;

  > div {
    display: ${(props) => (props.toggleLeftSide ? "flex" : "none")};
    width: ${(props) => (props.toggleLeftSide ? "40rem" : "0rem")};
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  width: 100%;
`;

const ReportSubMenu = styled.ul`
  margin: 15px;
`;

const MenuHeader = styled.li`
  background-color: ${(props) => props.theme.colors.lighterGrey};
  border: 0;
  /* border-radius: 5rem; */
  color: ${(props) => props.theme.colors.idataDarkGrey};

  display: flex;
  align-items: center;
  font-size: 2.25rem;
  font-weight: 500;
  padding: 1rem 1.25rem;
  transition: all 0.2s;
  position: sticky;
  top: 0;
  z-index: 10;
`;

const BookmarkIcon = styled.img`
  margin-right: 5px;
  height: 3rem;
`;

const Bookmarks = styled.div`
  flex: 1;
  overflow-y: auto;
  margin: 1rem 0;
`;

export default CustomReportMenu;
