import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";

import { countries, categories } from "data/menus/formOptions";
import { useAuth } from "contexts/AuthContext";
import { validateTempUser } from "redux/actions/admin";
import { setErrors, clearErrors } from "redux/actions/errors";
import { setNotification } from "redux/actions/notifications";
import { NotificationVariant } from "redux/reducers/notifications";

import PageHeader from "../../Common/misc/PageHeader";
import FormInput from "../../Common/forms/FormInput";
import SelectInput from "../../Common/forms/SelectInput";
import StandardButton from "../../Common/buttons/StandardButton";

import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";

type Errors = {
  firstName: string;
  lastName: string;
  phone: string;
  title: string;
  password: string;
  confirmPassword: string;
};

const Register: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  const { register } = useAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const errors = useSelector((state: { errors: Errors }) => state.errors);
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [company, setCompany] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [country, setCountry] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");

  useEffect(() => {
    setLoading(true);
    validateTempUser(token, navigate)
      .then((res) => {
        setEmail(res.email);
        setCompany(res.company);
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {
      dispatch(clearErrors());
    };
  }, [dispatch, location, token]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(clearErrors());

    if (password !== confirmPassword) {
      return dispatch(
        setErrors({ confirmPassword: "Passwords do not match " })
      );
    }

    const newUser = {
      email,
      company,
      firstName,
      lastName,
      phone,
      title,
      country,
      category,
      password,
    };

    try {
      setLoading(true);
      const result = await register(newUser);

      if (
        result.message ===
        "The email address is already in use by another account."
      ) {
        dispatch(
          setNotification(
            {
              message:
                "An account has already been registered with this email address",
              variant: NotificationVariant.danger,
            },
            false
          )
        );
      } else {
        dispatch(
          setNotification(
            {
              message: "Account successfully registered! You may log in now",
              variant: NotificationVariant.success,
            },
            false
          )
        );
        navigate("/");
      }
    } catch (error) {
      dispatch(setErrors({ message: "Failed to create an account" }));
    }

    setLoading(false);
  };

  return (
    <>
      <Header />
      <Container>
        <InnerContainer>
          <PageHeader>Register</PageHeader>
          <Form onSubmit={handleSubmit}>
            <Info>
              Please complete the form below to finish your registration.
            </Info>
            <FormInput
              type="email"
              name="email"
              placeholder="Email *"
              value={email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEmail(e.target.value)
              }
              disabled
            />
            <FormInput
              name="company"
              placeholder="Company *"
              value={company}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setCompany(e.target.value)
              }
              disabled
            />
            <FormInput
              name="firstName"
              placeholder="First Name *"
              value={firstName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setFirstName(e.target.value)
              }
              error={errors.firstName}
            />
            <FormInput
              name="lastName"
              placeholder="Last Name *"
              value={lastName}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setLastName(e.target.value)
              }
              error={errors.lastName}
            />
            <FormInput
              type="tel"
              name="phone"
              placeholder="Phone"
              value={phone}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setPhone(e.target.value)
              }
              error={errors.phone}
            />
            <FormInput
              name="title"
              placeholder="Professional Title *"
              value={title}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setTitle(e.target.value)
              }
              error={errors.title}
            />
            <SelectInput
              name="country"
              value={country}
              options={countries}
              onChange={(e) => setCountry(e.target.value)}
            />
            <SelectInput
              name="category"
              value={category}
              options={categories}
              onChange={(e) => setCategory(e.target.value)}
            />
            <FullWidthInput
              type="password"
              name="password"
              placeholder="Password *"
              value={password}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setPassword(e.target.value)
              }
              error={errors.password}
            />
            <FullWidthInput
              type="password"
              name="confirmPassword"
              placeholder="Confirm Password *"
              value={confirmPassword}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setConfirmPassword(e.target.value)
              }
              error={errors.confirmPassword}
            />
            <StandardButton type="submit" disabled={loading}>
              Register
            </StandardButton>
          </Form>
        </InnerContainer>
      </Container>
      <Footer />
    </>
  );
};

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.lighterGrey};
  color: #000;
  /* min-height: 100vh; */
  overflow-y: auto;
  width: 100%;
`;

const InnerContainer = styled.div`
  max-width: ${(props) => props.theme.bp.xxl}px;
  margin: auto;
  padding: 5rem;
  padding-top: 3rem;
  width: 100%;
  @media screen and (max-width: ${(props) => props.theme.bp.sm}px) {
    padding: 2rem;
  }
`;

const Form = styled.form`
  background-color: #fff;
  border: 1px solid ${(props) => props.theme.colors.darkGrey};
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  padding: 5.5rem;
  margin: 10rem auto;
  max-width: 75rem;
  @media screen and (max-width: ${(props) => props.theme.bp.sm}px) {
    padding: 2.5rem;
  }
`;

const FullWidthInput = styled(FormInput)`
  grid-column: 1 / -1;
`;

const Info = styled.p`
  grid-column: 1 / -1;
  margin-top: -3rem;
  padding: 2rem 0;
`;

export default Register;
