import React from "react";
import styled from "styled-components";

// copied from: https://github.com/ronmaple/sushi-in-vancouver/blob/master/client/src/components/subcomponents/Spinner.js

interface SpinnerProps {
  fullscreen?: boolean;
}

const Spinner: React.FC<SpinnerProps> = ({ fullscreen = true }) => (
  <Wrapper fullscreen={fullscreen}>
    <StyledSpinner viewBox="0 0 50 50">
      <circle
        className="path"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth="4"
      />
    </StyledSpinner>
  </Wrapper>
);

const Wrapper = styled.div<SpinnerProps>`
  display: flex;
  justify-content: center;
  min-height: ${(props) => (props.fullscreen ? "unset" : "0px")};
`;

const StyledSpinner = styled.svg`
  display: block;
  margin: auto;
  animation: rotate 2s linear infinite;
  /* margin: -25px 0 0 -25px; */
  width: 100px;
  height: 100px;

  & .path {
    stroke: #5652bf;
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;

export default Spinner;
