import axios from "axios";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { uploadReport, uploadReportNoWriteup } from "redux/actions/report";
import { clearErrors } from "redux/actions/errors";
import { Category, SubCategory } from "types/catalog";
import { ReportMeta } from "types/reports";
import { RootState } from "redux/reducers";

import StandardButton from "../../../Common/buttons/StandardButton";
import Spinner from "components/Common/misc/Spinner";

import { generatePageTitle } from "utils/general.utils";

import IconLegend from "assets/images/CategoryThumbnails/report-thumbnails-legend.png";

const ReportUpload: React.FC = () => {
  const dispatch = useDispatch();
  const { catalog, errors } = useSelector((state: RootState) => state);
  const [pastUploads, setPastUploads] = useState<ReportMeta[]>();
  const [selectedCategory, setSelectedCategory] = useState<Category>();
  const [categoryId, setCategoryId] = useState<number>(0);
  const [subCategoryId, setSubCategoryId] = useState<number>(0);
  const [title, setTitle] = useState<string>("");
  const [disableTitle, setDisableTitle] = useState<boolean>(false);
  const [year, setYear] = useState<string>("" + new Date().getFullYear());
  const [contents, setContents] = useState<any>([]);
  const [region, setRegion] = useState<string>("Global");
  const [icon, setIcon] = useState<string>("Arthroscope");
  const [description, setDescription] = useState<string>("");
  const [includesWriteup, setIncludesWriteup] = useState<boolean>(true);
  const [isReportUploading, setIsReportUploading] = useState<boolean>(false);

  useEffect(() => {
    document.title = generatePageTitle("Report Upload");
  });

  const getPastUploads = (categoryId, year) => {
    axios
      .get(`/reports/report-info?categoryId=${categoryId}&reportYear=${year}`)
      .then((res) => setPastUploads(res.data));
  };

  const renderPastUploads = () => {
    if (pastUploads) {
      return pastUploads.map((upload) => {
        return (
          <option key={upload.report_id} value={upload.report_id}>
            {upload.report_name}
          </option>
        );
      });
    }
  };

  const renderCategoryList = () => {
    const { categories } = catalog;

    return Object.keys(categories).map((c) => (
      <option key={categories[c].category_id} value={categories[c].category_id}>
        {categories[c].category_name}
      </option>
    ));
  };

  const renderSubCategoryList = () => {
    if (selectedCategory && selectedCategory.hasOwnProperty("sub_categories")) {
      const subCategories: SubCategory[] = selectedCategory.sub_categories;

      return subCategories.map((s) => (
        <option key={s.sub_category_id} value={s.sub_category_id}>
          {s.sub_category_name}
        </option>
      ));
    }
  };

  const handleSelectCategory = (e) => {
    const categoryId = parseInt(e.target.value);
    const category = catalog.categories.find(
      (c) => c.category_id === categoryId
    );
    setSelectedCategory(category);
    setCategoryId(categoryId);
    setDisableTitle(false);

    getPastUploads(categoryId, year);
  };

  const handlePastUploads = (e) => {
    if (+e.target.value === 0) {
      setDisableTitle(false);
      setTitle("");
      setDescription("");
      return;
    }

    // Get upload from pastUploads based on e.target.value, which is the report_id
    if (pastUploads) {
      const pastUpload = pastUploads.find(
        (element) => element.report_id === +e.target.value
      );
      if (pastUpload) {
        setDisableTitle(true);
        setSubCategoryId(pastUpload?.sub_category_id);
        setRegion(pastUpload?.region);
        setIcon(pastUpload?.icon);
        setDescription(pastUpload?.description);
        setTitle(pastUpload.report_name);
      }
    }
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    setContents(file);
  };

  const onReportUploadComplete = (error? : any) => {
    setIsReportUploading(false);
    if (error) {
      alert(error);
    } else {
      alert("Successfully uploaded!");
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(clearErrors());
    const formData = new FormData();
    formData.append("file", contents);
    formData.append("categoryId", categoryId.toString());
    formData.append("subCategoryId", subCategoryId.toString());
    formData.append("title", title);
    formData.append("year", year);
    formData.append("region", region);
    formData.append("icon", icon);
    formData.append("description", description);

    if (includesWriteup) {
      dispatch(uploadReport(formData, onReportUploadComplete));
      setIsReportUploading(true);
    } else {
      dispatch(uploadReportNoWriteup(formData, onReportUploadComplete));
    }
  };

  const renderYearList = () => {
    let currentYear = new Date().getFullYear() + 1;
    const limit = currentYear - 7;
    const allYears: number[] = [];

    while (currentYear >= limit) {
      allYears.push(currentYear);
      currentYear--;
    }

    function handleChange(year: string) {
      setYear(year.toString());
      setDisableTitle(false);
      getPastUploads(categoryId, year);
    }

    return allYears.map((y) => (
      <li key={y}>
        <input
          type="radio"
          name="year"
          value={y}
          onChange={() => handleChange(y.toString())}
          checked={year === y.toString()}
        />
        {y}
      </li>
    ));
  };

  const renderRegionList = () => {
    return (
      <>
        <label htmlFor="region-select">Please choose a region</label>
        <select
          id="region-select"
          onChange={(e) => setRegion(e.target.value)}
          value={region}
        >
          <option value="Global">Global</option>
          <option value="North America">North America</option>
          <option value="Europe">Europe</option>
          <option value="Asia Pacific">Asia Pacific</option>
          <option value="Latin America">Latin America</option>
        </select>
      </>
    );
  };

  const renderIconList = () => {
    return (
      <>
        <label htmlFor="icon-select">Please choose an icon</label>
        <select
          id="icon-select"
          onChange={(e) => setIcon(e.target.value)}
          value={icon}
        >
          <option value="Arthroscope">Arthroscope</option>
          <option value="Bandage">Bandage</option>
          <option value="Brain">Brain</option>
          <option value="Cell">Cell</option>
          <option value="Cosmo">Cosmo</option>
          <option value="Dental">Dental</option>
          <option value="Diabetes">Diabetes</option>
          <option value="ENT">ENT</option>
          <option value="Ear">Ear</option>
          <option value="Eye">Eye</option>
          <option value="Heart">Heart</option>
          <option value="Kidneys">Kidneys</option>
          <option value="Knee">Knee</option>
          <option value="MRI">MRI</option>
          <option value="OxygenTank">OxygenTank</option>
          <option value="PatientMonitoring">PatientMonitoring</option>
          <option value="Pharma">Pharma</option>
          <option value="Spine">Spine</option>
          <option value="Stomach">Stomach</option>
          <option value="Vascular">Vascular</option>
          <option value="Video">Video</option>
          <option value="WomensHealth">WomensHealth</option>
        </select>
      </>
    );
  };

  const renderDescription = () => {
    return (
      <>
        <label>Please enter a description</label>
        <textarea
          rows={10}
          cols={5}
          onChange={(e) => setDescription(e.target.value)}
          value={description}
          required
        ></textarea>
      </>
    );
  };

  return (
    <>
      {isReportUploading 
        ? <UploadInProgressPopup>
            <h2>Uploading report...</h2>
            <Spinner fullscreen={false}/> 
            <p>Don't refresh/close the page</p>
          </UploadInProgressPopup>
        : <></>}
      <PageTitle>Report Upload</PageTitle>
      <Container>
        <UploadForm onSubmit={handleSubmit}>
          <div>
            <p>
              Use dropdown below to fill form with previously uploaded report
              details after choosing <strong>category</strong> and{" "}
              <strong>year</strong>
            </p>
            <Select name="pastUploads" onChange={handlePastUploads}>
              <option value={0}>--- New Report (resets form) ---</option>
              {renderPastUploads()}
            </Select>
          </div>
          <div>
            <Input
              type="text"
              placeholder="Report title"
              name="title"
              onChange={(e) => setTitle(e.target.value)}
              value={title}
              disabled={disableTitle}
            />
            <Error>{errors.title}</Error>
          </div>
          <div>
            <Select
              name="categoryId"
              onChange={handleSelectCategory}
              value={categoryId}
            >
              <option value={0}>--- Please choose a category ---</option>
              {renderCategoryList()}
            </Select>
            <Error>{errors.category}</Error>
          </div>
          <div>
            <Select
              name="subCategory"
              onChange={(e) => setSubCategoryId(parseInt(e.target.value))}
              value={subCategoryId}
            >
              <option value={0}>--- Please choose a sub category ---</option>
              {renderSubCategoryList()}
            </Select>
            <Error>{errors.subCategory}</Error>
          </div>
          <YearList>{renderYearList()}</YearList>
          <GenericInput>{renderRegionList()}</GenericInput>
          <GenericInput>{renderIconList()}</GenericInput>
          <GenericInput>{renderDescription()}</GenericInput>
          <IncludesWriteupLabel>
            Includes writeup
            <input
              name="includesWriteup"
              type="checkbox"
              checked={includesWriteup}
              onChange={(e) => setIncludesWriteup(e.target.checked)}
            />
          </IncludesWriteupLabel>

          <div hidden={!includesWriteup}>
            <label htmlFor="contents">
              <em>Attach .zip file containing report contents</em>
            </label>
            <br />
            <input type="file" name="contents" onChange={handleFileUpload} />
          </div>
          <Error>{errors.file}</Error>
          <StandardButton type="submit" size="sm" mt="3rem">
            Upload
          </StandardButton>
        </UploadForm>
        <IconLegendDiv>
          <img src={IconLegend} alt="report thumbnails legend" />
        </IconLegendDiv>
      </Container>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

const PageTitle = styled.h2`
  color: ${(props) => props.theme.colors.idataDarkGrey};
`;

const UploadForm = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 5rem;
  min-height: 50rem;
  // max-width: 50rem;
`;

const YearList = styled.ul`
  display: flex;

  li {
    vertical-align: middle;

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
`;

const GenericInput = styled.div`
  display: flex;
  flex-direction: column;

  label,
  textarea {
    margin-bottom: 1rem;
  }

  select {
    margin-left: 1rem;
  }
`;

const Input = styled.input`
  background-color: transparent;
  border-bottom: 2px solid ${(props) => props.theme.colors.idataDarkGrey};
  border-radius: 0;
  font-size: 1.8rem;
  padding: 0.5rem;
  height: 4rem;
  width: 100%;
`;

const Select = styled.select`
  background-color: transparent;
  border: none;
  border-bottom: 2px solid ${(props) => props.theme.colors.idataDarkGrey};
  border-radius: 0;
  color: ${(props) => props.theme.colors.idataDarkGrey};
  cursor: pointer;
  font-size: 1.8rem;
  padding: 0.5rem;
  height: 5rem;
  min-width: 32rem;
  outline: none;

  &:active,
  &:focus {
    outline: none;
  }
`;

const Error = styled.p`
  color: ${(props) => props.theme.colors.red};
  font-size: 1.4rem;
  font-weight: 700rem;
`;

const IncludesWriteupLabel = styled.label`
  margin-bottom: 1rem;
`;

const IconLegendDiv = styled.div`
  padding-left: 6rem;
`;

const UploadInProgressPopup = styled.div`
  background: rgba(255, 255, 255, 0.75);
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export default ReportUpload;
