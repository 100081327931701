import React, { useState } from "react";
import styled from "styled-components";
import { MenuItem } from "types";

import MobileSearchBar from "./MobileSearchbar";
import MobileMenuItem from "./MobileMenuItem";
import { navbar } from "data/menus/navbar";
import hamburgerIcon from "assets/icons/Hamburger-Menu-Icon.svg";
import { useAuth } from "contexts/AuthContext";
import { Link } from "react-router-dom";

type ContainerProps = {
  readonly toggleDropdown: boolean;
};

const MobileMenu: React.FC = () => {
  const [toggleDropdown, setToggleDropdown] = useState<boolean>(false);
  const { currentUser } = useAuth();

  const handleAccess = (menu: MenuItem) => {
    if (menu.access.length > 0) {
      return currentUser && menu.access.includes(currentUser.role);
    } else if (currentUser && menu.key === "signin") {
      return false;
    }

    return menu;
  };

  return (
    <Container>
      <Button onClick={() => setToggleDropdown(!toggleDropdown)}>
        <img src={hamburgerIcon} alt="mobile menu icon" />
      </Button>
      <Dropdown toggleDropdown={toggleDropdown}>
        <MobileSearchBarWrapper hidden={!currentUser}>
          <MobileSearchBar />
        </MobileSearchBarWrapper>
        {navbar
          .filter(({ menu }) => handleAccess(menu))
          .map(({ menu }) => (
            <MobileMenuItem
              key={menu.key}
              menu={menu}
              isDropdown={menu.childMenu.length > 0}
              setToggleDropdown={setToggleDropdown}
            />
          ))}
        <Links>
          <li>
            <ExternalLink to={navbar[2].menu.path}>
              {navbar[2].menu.name}
            </ExternalLink>
          </li>
          <li>
            <ExternalLink to={navbar[3].menu.path}>
              {navbar[3].menu.name}
            </ExternalLink>
          </li>
          <li>
            <ExternalLink to={navbar[4].menu.path}>
              {navbar[4].menu.name}
            </ExternalLink>
          </li>
        </Links>
      </Dropdown>
    </Container>
  );
};

const Container = styled.div`
  display: none;

  @media screen and (max-width: 1290px) {
    display: block;
    margin: auto 0;
  }
`;

const Button = styled.button`
  padding: 0;
  margin-right: 1rem;
  margin-top: 3px;

  &:hover {
    opacity: 0.8;
  }
`;

const Dropdown = styled.div<ContainerProps>`
  background-color: ${(props) => props.theme.colors.idataDarkBlue};
  color: #fff;
  display: ${(props) => (props.toggleDropdown ? "block" : "none")};
  position: absolute;
  top: 8rem;
  left: 0;
  width: 100%;
  padding: 0 2rem;

  @media screen and (max-width: 1290px) {
    top: 5rem;
  }
`;

const Links = styled.ul`
  display: flex;
  flex-wrap: wrap;

  > *:not(:last-child) {
    margin-right: 5%;
  }
`;

const ExternalLink = styled(Link)`
  color: #fff;
  display: block;

  &:hover {
    color: #fff;
    text-decoration: underline;
  }
`;

const MobileSearchBarWrapper = styled.div`
  padding: 0 1rem;
`;

export default MobileMenu;
