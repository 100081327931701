import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "redux/reducers";
import { setFilters } from "redux/actions/catalog";

import Spinner from "components/Common/misc/Spinner";
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";

import CatalogSkeleton from "./CatalogSkeleton";
import { ptFilters, ptMenu } from "data/menus/catalogMenu";

import { generatePageTitle } from "utils/general.utils";
import { fetchPTCatalogData, resetFilters } from "redux/actions/pt";
import { fetchPTSubscriptions } from "redux/actions/subscriptions";
import { useAuth } from "contexts/AuthContext";

const PTList = React.lazy(() => import("./ChildComponents/Preview/PTList"));

const PT = () => {
  const { currentUser } = useAuth();
  const dispatch = useDispatch();
  const location = useLocation();
  const { pt } = useSelector((state: RootState) => state);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    dispatch(fetchPTCatalogData(setLoading));
    
    if (currentUser.email !== "support@idataresearch.net" &&
        currentUser.email !== "marketing@idataresearch.net") {
      dispatch(fetchPTSubscriptions(currentUser.usergroupData.id));
    }
  }, []);

  useEffect(() => {
    dispatch(resetFilters());
  }, [pt.filters.filter, pt.categories.length, dispatch]);

  // Change filter based on pathname
  useEffect(() => {
    switch (location.pathname) {
      case "/pt/subscriptions":
        dispatch(setFilters("filter", "Subscriptions"));
        dispatch(setFilters("subscribed", true));
        document.title = generatePageTitle("Subscriptions");
        break;
      case "/pt/favorites":
        dispatch(setFilters("filter", "Favorites"));
        dispatch(setFilters("subscribed", false));
        document.title = generatePageTitle("Favorites");
        break;
      default:
        dispatch(setFilters("filter", "All Procedure Tracker"));
        dispatch(setFilters("subscribed", false));
        document.title = generatePageTitle("All Procedure Tracker");
        break;
    }
  }, [dispatch, location.pathname]);

  if (loading) {
    return (
      <>
        <Header />
        <Spinner />
        <Footer />
      </>
    );
  } else {
    return (
      <>
        <Header />
        <Container>
          <CatalogSkeleton
            menu={ptMenu}
            filters={ptFilters}
            list={<PTList />}
            hideFilters={false}
            parent={"pt"}
            sectionName={"Procedure Tracker Catalog"}
          />
        </Container>
        <Footer />
      </>
    );
  }
};

const Container = styled.div`
  background-color: #f6f8f9;
  color: #000;
  /* min-height: 100vh; */
  overflow-y: auto;
  width: 100%;
`;

export default PT;
