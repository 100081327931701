import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import styled from "styled-components";

import AdminMenu from "./ChildComponents/AdminMenu";
import AdminFooter from "./ChildComponents/AdminFooter";
import AdminPanelHome from "./ChildComponents/AdminPanelHome";
import ReportUpload from "./ChildComponents/ReportUpload";
import ManageReports from "./ChildComponents/manage-reports/ManageReports";
import ManageClients from "./ChildComponents/manage-clients/ManageClients";
import ClientAccess from "./ChildComponents/client-access/ClientAccess";
import ImportGlobalDashboard from "./ChildComponents/ImportGlobalDashboard";
import Help from "./ChildComponents/Help";

import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
import ImportMedSKUDashboard from "./ChildComponents/ImportMedSKUDashboard";
import ImportPTDashboard from "./ChildComponents/ImportPTDashboard";
import ImportSurveyDashboard from "./ChildComponents/ImportSurveyDashboard";
import UserReport from "./UserReport";

const Admin: React.FC = () => {
  const [toggleFooter, setToggleFooter] = useState<boolean>(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener("scroll", handleScroll);

    document.getElementById("adminContainer")?.parentElement?.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (document.getElementById("adminContainer")?.parentElement?.scrollTop! > 100) {
      setToggleFooter(true);
    } else {
      setToggleFooter(false);
    }
  };

  return (
    <>
      <Header />
      <Container>
        <AdminMenu />
        <InnerContainer id="adminContainer">
          <Routes>
            <Route path="/" element={<AdminPanelHome />} />
            <Route path="/report-upload" element={<ReportUpload />} />
            <Route path="/manage-reports" element={<ManageReports />} />
            <Route path="/manage-clients" element={<ManageClients />} />
            <Route path="/client-access" element={<ClientAccess />} />
            <Route path="/user-report" element={<UserReport />} />
            <Route
              path="/import-global-dashboard"
              element={<ImportGlobalDashboard />}
            />
            <Route
              path="/import-medsku-dashboard"
              element={<ImportMedSKUDashboard />}
            />
            <Route
              path="/import-pt-dashboard"
              element={<ImportPTDashboard />}
            />
            <Route
              path="/import-survey-dashboard"
              element={<ImportSurveyDashboard />}
            />
            <Route path="/help" element={<Help />} />
          </Routes>
        </InnerContainer>
        {toggleFooter && <AdminFooter />}
      </Container>
      <Footer />
    </>
  );
};

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.lighterGrey};
  color: #000;
  /* min-height: 90vh; */
  overflow-y: auto;
  width: 100%;
`;

const InnerContainer = styled.div`
  max-width: ${(props) => props.theme.bp.xxl}px;
  margin: auto;
  padding: 5rem;
  padding-top: 3rem;
`;

export default Admin;
