import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "redux/reducers";
import { setFilters } from "redux/actions/catalog";

import Spinner from "components/Common/misc/Spinner";
import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";

import CatalogSkeleton from "./CatalogSkeleton";
import { surveyFilters, surveyMenu } from "data/menus/catalogMenu";

import { generatePageTitle } from "utils/general.utils";
import { fetchSurveyCatalogData, resetFilters } from "redux/actions/survey";
import { fetchSurveySubscriptions } from "redux/actions/subscriptions";
import { useAuth } from "contexts/AuthContext";

const SurveyList = React.lazy(() => import("./ChildComponents/Preview/SurveyList"));

const Surveys = () => {
  const { currentUser } = useAuth();
  const dispatch = useDispatch();
  const location = useLocation();
  const { survey } = useSelector((state: RootState) => state);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    dispatch(fetchSurveyCatalogData(setLoading));
    
    if (currentUser.email !== "support@idataresearch.net" &&
        currentUser.email !== "marketing@idataresearch.net") {
      dispatch(fetchSurveySubscriptions(currentUser.usergroupData.id));
    }
  }, []);

  useEffect(() => {
    dispatch(resetFilters());
  }, [survey.filters.filter, survey.categories.length, dispatch]);

  // Change filter based on pathname
  useEffect(() => {
    switch (location.pathname) {
      case "/survey/subscriptions":
        dispatch(setFilters("filter", "Subscriptions"));
        dispatch(setFilters("subscribed", true));
        document.title = generatePageTitle("Subscriptions");
        break;
      case "/survey/favorites":
        dispatch(setFilters("filter", "Favorites"));
        dispatch(setFilters("subscribed", false));
        document.title = generatePageTitle("Favorites");
        break;
      default:
        dispatch(setFilters("filter", "All Surveys"));
        dispatch(setFilters("subscribed", false));
        document.title = generatePageTitle("All Surveys");
        break;
    }
  }, [dispatch, location.pathname]);

  if (loading) {
    return (
      <>
        <Header />
        <Spinner />
        <Footer />
      </>
    );
  } else {
    return (
      <>
        <Header />
        <Container>
          <CatalogSkeleton
            menu={surveyMenu}
            filters={surveyFilters}
            list={<SurveyList />}
            hideFilters={false}
            parent={"survey"}
            sectionName={"Surveys Catalog"}
          />
        </Container>
        <Footer />
      </>
    );
  }
};

const Container = styled.div`
  background-color: #f6f8f9;
  color: #000;
  /* min-height: 100vh; */
  overflow-y: auto;
  width: 100%;
`;

export default Surveys;
