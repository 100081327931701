import React from "react";
import {
  BrowserRouter,
} from "react-router-dom";
import { Routes, Route } from "react-router-dom";
import GlobalStyle from "styles/GlobalStyle";
import { Theme } from "styles/theme";
import { AuthProvider } from "./contexts/AuthContext";
import styled from "styled-components";

import Home from "components/Pages/home/Home";
import Account from "components/Pages/account/Account";
import Admin from "components/Pages/admin/Admin";
import Catalog from "components/Pages/catalog/Catalog";
import MedSKU from "components/Pages/catalog/MedSKU";
import PT from "components/Pages/catalog/PT";
import Survey from "components/Pages/catalog/Survey";
import Contact from "components/Pages/contact/Contact";
import Register from "components/Pages/register/Register";
import RegistrationRequest from "components/Pages/register/RegistrationRequest";
import Report from "components/Pages/report/Report";
import Dashboard from "components/Pages/report/ChildComponents/Dashboard";
import CustomReport from "components/Pages/custom-report/CustomReport";
import SignOut from "components/Pages/SignOut";
import ClientAccess from "components/Pages/admin/ChildComponents/client-access/ClientAccess";
import PasswordRecovery from "components/Pages/password-recovery/PasswordRecovery";
import ErrorBoundary from "./components/Common/misc/ErrorBoundary";
import Inactive from "components/Common/misc/Inactive";
import ZimmerBiometSignIn from "components/Pages/home/ChildComponents/ZimmerBiometSignIn";
import Help from "components/Pages/Help";
import UserReport from "components/Pages/admin/UserReport";
import PrivateRoutes from "components/Common/security/PrivateRoutes";
import AdminRoutes from "components/Common/security/AdminRoutes";
import SalesRoutes from "components/Common/security/SalesRoutes";

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <GlobalStyle />
        <Theme>
          <Layout>
            <ErrorBoundary>
              <Inactive />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/help" element={<Help />} />
                <Route path="/sign-in/zb" element={<ZimmerBiometSignIn />} />
                <Route path="/sign-out" element={<SignOut />} />
                <Route path="/password-recovery" element={<PasswordRecovery />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/register/:token" element={<Register />} />
                <Route path="/registration-request/:token" element={<RegistrationRequest />} />

                <Route element={<PrivateRoutes />}>
                  <Route path="/catalog/*" element={<Catalog />} />

                  <Route path="/medsku/all-medsku" element={<MedSKU />} />
                  <Route path="/medsku/favorites" element={<MedSKU />} />
                  <Route path="/medsku/subscriptions" element={<MedSKU />} />

                  <Route path="/pt/all-procedure-tracker" element={<PT />} />
                  <Route path="/pt/favorites" element={<PT />} />
                  <Route path="/pt/subscriptions" element={<PT />} />

                  <Route path="/survey/all-surveys" element={<Survey />} />
                  <Route path="/survey/favorites" element={<Survey />} />
                  <Route path="/survey/subscriptions" element={<Survey />} />

                  <Route path="/dashboard/*" element={<Account />} />

                  <Route path="/reports/global-dashboard/medcore/:id/*" element={<Dashboard />} />
                  <Route path="/reports/global-dashboard/:id/*" element={<Dashboard />} />
                  <Route path="/reports/dashboard/:id/*" element={<Dashboard />} />
                  <Route path="/medsku/dashboard/:id/*" element={<Dashboard />} />
                  <Route path="/pt/dashboard/:id/*" element={<Dashboard />} />
                  <Route path="/survey/dashboard/:id/*" element={<Dashboard />} />

                  <Route path="/reports/:id/*" element={<Report />} />
                  <Route path="/custom-reports/:id/*" element={<CustomReport />} />
                </Route>

                <Route element={<AdminRoutes />}>
                  <Route path="/admin-panel/*" element={<Admin />} />
                </Route>

                <Route element={<SalesRoutes />}>
                  <Route path="/client-access" element={<ClientAccess />} />
                  <Route path="/user-report" element={<UserReport />} />
                </Route>
              </Routes>
            </ErrorBoundary>
          </Layout>
        </Theme>
      </AuthProvider>
    </BrowserRouter>
  );
};

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
  > :nth-child(2) {
    flex-grow: 1;
  }
  /* grid-template-columns: 100%; */
`;

export default App;
