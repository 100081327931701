import React from "react";
import { Routes, Route } from "react-router-dom";
import styled from "styled-components";

import PageHeader from "../../Common/misc/PageHeader";
import AccountMenu from "./ChildComponents/AccountMenu";
import AccountInformation from "./ChildComponents/AccountInformation/AccountInformation";
import EditAccountInformation from "./ChildComponents/AccountInformation/EditAccountInformation";
import ChangePassword from "./ChildComponents/AccountInformation/ChangePassword";
import MyOrders from "./ChildComponents/MyOrders";
import CustomReports from "./ChildComponents/CustomReports";
import TeamManagement from "./ChildComponents/TeamManagement/TeamManagement";
import CustomResearchRequest from "./ChildComponents/CustomResearchRequest";

import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";

const Profile: React.FC = () => (
  <>
    <Header />
    <Container>
      <InnerContainer>
        <PageHeader>My Account</PageHeader>
        <AccountMenu />
        <Routes>
          <Route path="/account-info" element={<AccountInformation />} />
          <Route
            path="/edit-account-info"
            element={<EditAccountInformation />}
          />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route path="/my-orders" element={<MyOrders />} />
          <Route path="/custom-reports" element={<CustomReports />} />
          <Route path="/team-management" element={<TeamManagement />} />
          <Route
            path="/custom-research-request"
            element={<CustomResearchRequest />}
          />
        </Routes>
      </InnerContainer>
    </Container>
    <Footer />
  </>
);

const Container = styled.div`
background-color: ${(props) => props.theme.colors.lighterGrey};
color: #000;
/* min-height: 100vh; */
overflow-y: auto;
width: 100%;
`;

const InnerContainer = styled.div`
max-width: ${(props) => props.theme.bp.xxl}px;
margin: auto;
padding: 5rem;
padding-top: 3rem;
display: grid;
grid-gap: 3rem;
grid-template-columns: minmax(min-content, 35rem) minmax(50rem, 1fr);
@media screen and (max-width: ${(props) => props.theme.bp.md}px) {
padding: 2rem;
position: relative;
grid-template-columns: 1fr;

h1 {
margin-left: 60px;
}
}
`;

export default Profile;
