import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { useAuth } from "contexts/AuthContext";
import { shouldRefetchCustomReport } from "utils/custom.utils";
import { fetchCustomReportData } from "redux/actions/custom-report";
import { RootState } from "redux/reducers";

import CustomReportHeader from "./ChildComponents/Header/CustomReportHeader";
import CustomReportMenu from "./ChildComponents/Menu/CustomReportMenu";
import CustomReportMain from "./ChildComponents/Content/CustomReportMain";
import CustomReportSidebar from "./ChildComponents/Sidebar/CustomReportSidebar";
import CustomReportFooter from "../report/ChildComponents/ReportFooter";
import Spinner from "../../Common/misc/Spinner";

import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";

import { generatePageTitle } from "utils/general.utils";

const CustomReport: React.FC = () => {
  const { currentUser } = useAuth();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const customReport = useSelector((state: RootState) => state.customReport);

  const [loading, setLoading] = useState<boolean>(true);
  const [toggleLeftSide, setToggleLeftSide] = useState<boolean>(true);
  const [toggleRightSide, setToggleRightSide] = useState<boolean>(true);

  useEffect(() => {
    if (customReport.reportData.c_report_name) {
      document.title = generatePageTitle(
        `${customReport.reportData.c_report_name} - Custom Report`
      );
    } else {
      document.title = generatePageTitle(`Loading Custom Report...`);
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [customReport.currentChapter]);

  useEffect(() => {
    if (window.innerWidth > 1000 && window.innerWidth <= 1500) {
      setToggleRightSide(false);
    } else if (window.innerWidth <= 1000) {
      setToggleLeftSide(false);
      setToggleRightSide(false);
    }
  }, []);

  useEffect(() => {
    // only fetch data if not already in redux store
    // or metadata is different
    if (currentUser.usergroupData) {
      if (shouldRefetchCustomReport(customReport, id)) {
        dispatch(
          fetchCustomReportData(
            id,
            currentUser.company,
            setLoading,
            currentUser.usergroupData.subsidiaryCompanies
          )
        );
      } else {
        setLoading(false);
      }
    }
  }, [
    currentUser,
    customReport,
    dispatch,
    id,
    currentUser.usergroupData.subsidiaryCompanies,
  ]);

  return loading ? (
    <>
      <Header />
      <Spinner />
      <Footer />
    </>
  ) : (
    <>
      <Header />
      <Container>
        <CustomReportHeader
          toggleLeftSide={toggleLeftSide}
          setToggleLeftSide={setToggleLeftSide}
          toggleRightSide={toggleRightSide}
          setToggleRightSide={setToggleRightSide}
        />
        <InnerContainer id={"InnerContainer"}>
          <CustomReportMenu
            toggleLeftSide={toggleLeftSide}
          />
          <CustomReportMain />
          <CustomReportSidebar
            toggleRightSide={toggleRightSide}
          />
        </InnerContainer>
        <CustomReportFooter
          scrollPositions={customReport.search.scrollPositions}
        />
      </Container>
      <Footer />
    </>
  );
};

const Container = styled.div`
  background-color: ${(props) => props.theme.colors.lighterGrey};
  color: #000;
  /* min-height: 100vh; */
  
  /* 'auto' causes some performance issues when tables are being styled (GLUR22) */
  /* Set to 'hidden' for now and will change to 'auto' once tables are styled */
  overflow-y: hidden;

  width: 100%;
`;

const InnerContainer = styled.div`
  display: grid;
  grid-column-gap: 1rem;
  grid-template-columns: max-content auto max-content;
`;

export default CustomReport;
