import React, { useEffect } from "react";
import styled from "styled-components";

import { generatePageTitle } from "utils/general.utils";

import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";

const SignOut: React.FC = () => {
  window.location.href = "/";

  useEffect(() => {
    document.title = generatePageTitle("Signing out...");
  });

  return (
    <>
      <Header />
      <SignOutDiv>
        <h1>Signing out...</h1>
      </SignOutDiv>
      <Footer />
    </>
  );
};

const SignOutDiv = styled.div`
  /* min-height: 90vh; */
  overflow-y: auto;
  max-width: 75rem;
  margin: 0 auto;
  padding: 2rem;
`;

export default SignOut;
