import React from "react";
import styled from "styled-components";
import upArrow from "assets/icons/arrow-up.svg";

const AdminFooter: React.FC = () => (
  <Container>
    <BTTLink
      onClick={() => document.getElementById("adminContainer")?.parentElement?.scrollTo({
        left: 0,
        top: 0,
        behavior: "smooth"
      })}
      title="Back to top"
    >
      <UpArrow src={upArrow} alt="up arrow" />
    </BTTLink>
  </Container>
);

const Container = styled.div`
  grid-column: 1 / -1;
  position: sticky;
  bottom: 2rem;
  left: 0;
  pointer-events: none;

  @media screen and (max-width: ${(props) => props.theme.bp.md}px) {
    order: 3;
  }
`;

const BTTLink = styled.button`
  color: ${(props) => props.theme.colors.idataDarkBlue};
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 2rem;
  background-color: ${(props) => props.theme.colors.grey};
  padding: 0.5rem;
  pointer-events: auto;
  position: absolute;
  right: 3.5rem;
  bottom: 0;

  &:hover {
    opacity: 0.75;
  }
`;

const UpArrow = styled.img`
  /* margin-bottom: -1rem; */
`;

export default AdminFooter;
