import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { RootState } from "redux/reducers";
import { useAuth } from "contexts/AuthContext";

import { setFilters, resetFilters } from "redux/actions/catalog";
import { FilterType } from "types/catalog";
import StandardButton from "components/Common/buttons/StandardButton";

interface CatalogFilterButtonsProps {
  parent: string;
  filtersData: FilterType[];
}

export const CatalogFilterButtons = ({
  filtersData,
  parent,
}: CatalogFilterButtonsProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { filters } = useSelector((state: RootState) => state.catalog);
  const { currentUser } = useAuth();

  const handleClick = (e, filter) => {
    dispatch(resetFilters());

    // Add to entry to history stack depending on selected filter
    navigate(
      `/${parent}/${filter.text.replace(/\s+/g, "-").toLowerCase()}`
    );

    // Subscriptions
    if (filter.id) {
      if (filter.id === 1) {
        dispatch(setFilters("filter", filter.text));
        dispatch(setFilters("subscribed", true));
      } else {
        dispatch(setFilters("filter", filter.text));
        dispatch(setFilters("subscribed", false));
      }
    }
  };

  return (
    <Container>
      {filtersData.map((item) => (
        <CustomButton
          type={"button"}
          size={"xs"}
          outline={filters.filter !== item.text}
          onClick={(e) => handleClick(e, item)}
          key={item.id}
        >
          {item.text}
        </CustomButton>
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;

  button {
    margin-right: 1rem;
    width: max-content;
  }

  @media screen and (max-width: 540px) {
    flex-wrap: wrap;

    > *:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
`;

const CustomButton = styled(StandardButton)`
  height: unset;
  font-size: 1.7rem;
  padding: 0.75rem 1.5rem;
`;

export default CatalogFilterButtons;
