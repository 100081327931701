import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";

import { useAuth } from "contexts/AuthContext";
import { CustomReport } from "types/custom";
import { RootState } from "redux/reducers";
import { fetchCustomReportMetadata } from "redux/actions/custom-report";
import { isSubscribed } from "utils/subscriptions.utils";
import dblBookmarksIcon from "assets/icons/dbl-bookmarks-icon.svg";

import CustomBookmark from "./CustomBookmark";
import BookmarkModal from "./BookmarkModal";

import Spinner from "components/Common/misc/Spinner";

interface IButtonProps {
  disabled?: boolean;
}

const CustomBookmarkMenu = () => {
  const dispatch = useDispatch();
  const { currentUser } = useAuth();
  const { report, customReport, subscriptions } = useSelector(
    (state: RootState) => state
  );
  const [toggleModal, setToggleModal] = useState<boolean>(false);
  const [subscribed, setSubscribed] = useState<boolean>(false);
  const [
    selectedCustomReport,
    setSelectedCustomReport,
  ] = useState<CustomReport>();
  const [loading, setLoading] = useState<boolean>(true);

  // Count of Custom Reports created by current user
  const [customReportsCount, setCustomReportsCount] = useState<number>(0);

  useEffect(() => {
    if (currentUser.usergroupData) {
      const subbed = isSubscribed(
        subscriptions,
        report.reportMeta.report_id,
        currentUser,
        "active"
      );
      setSubscribed(subbed);

      if (customReport.metadata.length === 0) {
        dispatch(
          fetchCustomReportMetadata([currentUser.usergroupData.id], setLoading)
        );
      } else {
        setLoading(false);

        // Count how many Custom Reports created by current user
        const email = currentUser.email;
        const userCustomReports = customReport.metadata.filter(
          (customReport) => customReport.user_email === email
        );
        setCustomReportsCount(userCustomReports.length);
      }
    }
  }, [
    customReport.metadata.length
  ]);

  return (
    <Container>
      <MenuHeader className="menuHeader">
        <div>
          <BookmarkIcon src={dblBookmarksIcon} alt="bookmark icon" /> Custom
          Reports{" "}
        </div>
        {subscribed && (
          <CreateNewButton
            disabled={
              customReportsCount >= 10 &&
              currentUser.email !== "support@idataresearch.net" &&
              currentUser.email !== "marketing@idataresearch.net"
            }
            onClick={() => setToggleModal(!toggleModal)}
          >
            + Create
            <TextSpan
              hidden={
                customReportsCount < 10 ||
                currentUser.email === "support@idataresearch.net" ||
                currentUser.email === "marketing@idataresearch.net"
              }
            >
              You may only create up to 10 Custom Reports. Delete another Custom
              Report if you want to create a new Custom Report.
            </TextSpan>
          </CreateNewButton>
        )}
      </MenuHeader>
      <ReportSubMenu>
        {loading ? (
          <Spinner />
        ) : (
          customReport.metadata.map((item: CustomReport) => {
            if (item.user_email !== "support@idataresearch.net") {
              return (
                <CustomBookmark
                  key={item.c_report_id}
                  item={item}
                  currentUser={currentUser}
                  setSelectedCustomReport={setSelectedCustomReport}
                  setToggleModal={setToggleModal}
                />
              );
            } else {
              return null;
            }
          })
        )}
      </ReportSubMenu>
      <BookmarkModal
        toggleModal={toggleModal}
        setToggleModal={setToggleModal}
        selectedCustomReport={selectedCustomReport}
        setSelectedCustomReport={setSelectedCustomReport}
      />
    </Container>
  );
};

const Container = styled.div`
  flex: 1;
  overflow-y: auto;
  margin: 1rem 0;
`;

const ReportSubMenu = styled.ul`
  padding-right: 2rem;
`;

const MenuHeader = styled.div`
  background-color: ${(props) => props.theme.colors.lighterGrey};
  border: 0;
  /* border-radius: 5rem; */
  color: ${(props) => props.theme.colors.idataDarkGrey};
  font-size: 2.25rem;
  font-weight: 500;
  transition: all 0.2s;

  display: flex;
  align-items: center;
  padding: 1rem 1.25rem;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 10;

  > div {
    display: flex;
    padding: 2px;
  }
`;

const BookmarkIcon = styled.img`
  margin-right: 5px;
`;

const disabledStyles = () => {
  return css`
    cursor: not-allowed;
  `;
};

const CreateNewButton = styled.button<IButtonProps>`
  color: ${(props) => props.theme.colors.idataDarkBlue};
  font-size: 2rem;
  padding: 0;
  padding-top: 4px;
  overflow: visible;

  ${(props) => (props.disabled ? disabledStyles() : null)}

  &:hover {
    opacity: 0.8;
  }

  &:hover span {
    visibility: visible;
  }
`;

const TextSpan = styled.span`
  visibility: hidden;
  background-color: ${(props) => props.theme.colors.red};
  color: white;
  padding: 10px;
  border-radius: 5px;
  width: 304px;

  position: absolute;
  z-index: 1;
  top: 50px;
  right: 0px;
`;

export default CustomBookmarkMenu;
