import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import searchIcon from "assets/icons/icon-search-blue.svg";
import closeIcon from "assets/icons/close-icon-blue.svg";
import { RootState } from "redux/reducers";
import {
  searchCustomReport,
  resetCustomReportSearch,
} from "redux/actions/custom-report";
import { clearErrors } from "redux/actions/errors";
import { useQuery } from "utils/search.utils";

const CustomReportSearch = () => {
  const dispatch = useDispatch();
  const term = useQuery().get("term");
  const { customReport, errors } = useSelector((state: RootState) => state);
  const [query, setQuery] = useState<string>("");

  useEffect(() => {
    if (term) {
      setQuery(term);
    }
  }, [term]);

  const useReset = (resetCustomReportSearch) => {
    useEffect(() => {
      dispatch(resetCustomReportSearch(customReport));
    }, [resetCustomReportSearch]);
  };

  useReset(resetCustomReportSearch);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(clearErrors());
    if (!query) return;

    dispatch(
      searchCustomReport(
        customReport.contentData,
        query,
        customReport.search.query
      )
    );
  };

  const handleReset = async () => {
    setQuery("");
    dispatch(await resetCustomReportSearch(customReport));
  };

  return (
    <Container autoComplete="off" onSubmit={handleSubmit}>
      <Input
        type="text"
        onChange={(e) => setQuery(e.target.value)}
        value={query}
        placeholder="Search This Report"
        // disabled={customReport.search.query}
      />
      {!customReport.search.query ? (
        <SearchButton type="submit">
          <img src={searchIcon} alt="search glass" />
        </SearchButton>
      ) : (
        <SearchButton type="reset" onClick={handleReset}>
          <img src={closeIcon} alt="close icon" />
        </SearchButton>
      )}
      <Error>{errors.search}</Error>
    </Container>
  );
};

const Container = styled.form`
  border: 1px solid ${(props) => props.theme.colors.idataBlue};
  border-radius: 5rem;
  display: flex;
  align-items: center;
  position: relative;
  height: 4.75rem;
  // flex: 2 1 0;
  overflow: hidden;
  width: fit-content;

  // @media screen and (max-width: 1305px) {
  //   width: 90vw;
  // }
`;

const Input = styled.input`
  background-color: transparent;
  color: ${(props) => props.theme.colors.idataBlue};
  font-size: 1.8rem;
  padding: 0rem 2rem;
  padding-right: 0;
  height: 6rem;
  /* flex: 2 1 0; */
  width: 27rem;

  &::placeholder {
    color: ${(props) => props.theme.colors.idataBlue};
  }
  @media screen and (max-width: ${(props) => props.theme.bp.xl}px) {
    font-size: 1.6rem;
  }
`;

const SearchButton = styled.button`
  background: transparent;
  border: none;
  /* padding: 0;
  height: 4rem;
  width: 4rem; */
  border-radius: 0;
  padding: 0 1.75rem;
  margin-left: 0 !important;
  height: 100%;
  display: flex;
  background-color: rgba(0, 0, 0, 0.05);

  > img {
    margin: auto;
    height: 3rem;
  }

  &:hover {
    opacity: 0.5;
  }

  @media screen and (max-width: ${(props) => props.theme.bp.lg}px) {
    margin: 0;
  }
`;

const Error = styled.p`
  color: ${(props) => props.theme.colors.red};
  font-size: 1.4rem;
  font-weight: 700rem;
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
`;

export default CustomReportSearch;
