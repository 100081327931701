import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import bg from "assets/images/Homepage-Background-1.jpg";

import { useAuth } from "contexts/AuthContext";
import { setNotification } from "redux/actions/notifications";
import { NotificationVariant } from "redux/reducers/notifications";

import Header from "components/Header/Header";
import Footer from "components/Footer/Footer";
import Diagram from "./ChildComponents/Diagram";
import LoginForm from "./ChildComponents/LoginForm";

type ContainerProps = {
  readonly bg: string;
};

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useAuth();

  useEffect(() => {
    document.title = "iData Online";
  }, []);

  useEffect(() => {
    if (currentUser && currentUser.usergroupData) {
      // Go to catalog if currentUser is enabled and not expired or a part of the admin company
      if (
        (currentUser.hasOwnProperty("enabled") &&
          currentUser.enabled &&
          Date.parse(currentUser.usergroupData.expiry) > Date.now()) ||
        currentUser.company === "idata-admin"
      ) {
        navigate("/catalog/all-reports");
      } else if (Date.parse(currentUser.usergroupData.expiry) < Date.now()) {
        dispatch(
          setNotification(
            {
              message:
                "Sorry for the inconvenience, but your subscription has expired. Please contact your sales representative.",
              variant: NotificationVariant.danger,
            },
            false
          )
        );
      } else if (
        currentUser.hasOwnProperty("enabled") &&
        !currentUser.enabled
      ) {
        dispatch(
          setNotification(
            {
              message:
                "Your account has not been enabled. Please contact your Team Leader or your sales representative.",
              variant: NotificationVariant.danger,
            },
            false
          )
        );
      }
    }
  }, [dispatch, location, currentUser]);

  return (
    <>
      <Header />
      <Container bg={bg}>
        <InnerContainer>
          <Diagram />
          <LoginForm />
        </InnerContainer>
      </Container>
      <Footer />
    </>
  );
};

const Container = styled.div<ContainerProps>`
  background: url(${(props) => props.bg});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  /* min-height: 90vh; */
  overflow-y: auto;
  width: 100%;
`;

const InnerContainer = styled.div`
  margin: auto;
  min-height: 100%;
  max-width: 128rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  @media screen and (max-width: ${(props) => props.theme.bp.lg}px) {
    flex-direction: column;
  }
`;

export default Home;
